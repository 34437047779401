.app {
  width: 100%;
  height: 100vh;
}

.view_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wht_bg_a {
  background: white;
}

.ntv_bg_a {
  background: rgba(212, 13, 22, 1);
}

.ntv_bg_b {
  background: rgba(212, 13, 22, 0.5);
}

.gry_bg_a {
  background: rgba(136, 136, 136, 0.7);
}

.mrd_bg_a {
  background: #c1aba6;
}

.blu_bg_a {
  background: #6667ab;
}

.blu_bg_b {
  background: #a4e9d5;
}

.pnk_bg_a {
  background: #ed254e;
}

.brd_rg_ntv_a {
  border-right: 3px solid rgba(212, 13, 22, 0.5);
}

.crs_pointer {
  cursor: pointer;
}

.crs_default {
  cursor: default;
}

.color_wht_a {
  color: white;
}

.color_gry_a {
  color: #888888;
}

.color_gry_b {
  color: rgba(136, 136, 136, 0.5);
}

.txt_20 {
  font-size: 20px;
}

.txt_16 {
  font-size: 16px;
}

.txt_14 {
  font-size: 14px;
}

.txt_12 {
  font-size: 12px;
}

.single_line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn_hvr_gry {
  cursor: pointer;
}

.btn_hvr_gry:hover {
  background: rgba(136, 136, 136, 0.1);
}

.fr_table td,
.fr_table th {
  min-width: 140px;
  text-align: left;
  padding: 8px 10px;
}

.fr_table:nth-child(even) {
  background-color: rgba(217, 217, 217, 0.5);
}

tbody .fr_table:hover {
  background-color: rgb(217, 217, 217);
}

.ctn_dropdown {
  height: 40px;
  min-width: 150px;
  display: flex;
}

.chd_dropdown {
  width: 100%;
  flex: 1;
}

.foc_dropdown {
  border-left: 1px solid rgb(217, 217, 217);
  border-right: 1px solid rgb(217, 217, 217);
  border-top: 1px solid rgb(217, 217, 217);
}

.ctn_choice_dropdown {
  margin-bottom: 20px;
  border-left: 1px solid rgb(217, 217, 217);
  border-right: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
}

.disable_view {
  visibility: hidden;
}

.ctn_button {
  height: 40px;
  min-width: 100px;
  display: flex;
}

.chd_button {
  flex: 1;
  padding: 0 10px;
  border-radius: 8px;
}

.ctn_input {
  display: flex;
  min-width: 150px;
  height: 40px;
}

input {
  padding: 0 10px;
  background: transparent;
  outline: none;
  border-width: 0;
  flex: 1;
}

.in_deactive {
  pointer-events: none;
  font-style: italic;
}

::placeholder {
  color: rgba(136, 136, 136, 0.5);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.5);
}

::-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.5);
}

.admin_nav_ctn {
  height: 60px;
  width: 100%;
}

.adm_tbr_ctn {
  height: 50px;
  width: 100%;
}

.adm_tbr_act {
  min-width: 60px;
}

.adm_tbl_ctn {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.mdl_ctn {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  overflow-y: auto;
}

.mdl_err_ctn {
  width: 100%;
  max-width: 500px;
}

.mdl_wrp {
  margin: 0 20px;
  padding: 20px;
  border-radius: 15px;
}

.loading_spinner {
  display: inline-block;
  width: 34px;
  height: 34px;
}

.loading_spinner:after {
  content: ' ';
  display: block;
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loading_spinner 1.2s linear infinite;
}

@keyframes loading_spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hide_block_andro {
  display: block;
}

@media only screen and (max-width : 430px) {

  .hide_block_andro {
    display: none;
  }

}


:root {
  --bs-breadcrumb-divider: '>';
  background-color: white;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-weight: 500;
  color: #fff;
  --bs-breadcrumb-divider-color: white
}

.modalImage {
  width: 160px;
  height: 140px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.modalImageDetail {
  width: 560px;
  height: 540px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.modalImageSs {
  width: 260px;
  height: 240px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.modalFull {
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}


.modalImagePr {
  width: 300px;
  height: 250px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.modalDet {
  position: absolute;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
  background: rgba(39, 48, 39, 0.3);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
  width: 660px;
  height: 640px;
  /* Green background with 30% opacity */
  ;
}

.modalDet::backdrop {
  background: hsl(0 0% 0% / 50%);
  opacity: 0.5;
  width: auto;
}
